<template>
	<div id="main">
		<v-index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>教学</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">课表查询</a></el-breadcrumb-item>
			</el-breadcrumb>

      <v-weburl-iframe class="content" url="teacher/table" />
		</v-index>
	</div>
</template>

<script>
import VWeburlIframe from "@/components/weburl-iframe";
import VIndex from "@/components/common";

export default {
	components: {
    VIndex,
    VWeburlIframe,
	}
};
</script>

<style lang="scss" scoped>
.content {
  height: 100%;
}
</style>
